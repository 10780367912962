<template>
  <v-app>
    <v-main id="app">
      <div style="display: grid; place-items: center; height: 100vh">
        <div>
          <v-row class="mb-12">
            <v-col class="d-flex justify-center">
              <span class="text-md-h2">URL-Shortener</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card color="primary" class="mx-3">
                <v-container>
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <span class="text-h5 font-weight-bold secondary--text"
                        >Shorten your URL</span
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-center px-7">
                      <span
                        >Paste in your long URL, hit Enter and get your
                        shortened URL.</span
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-center px-7">
                      <v-text-field
                        color="secondary"
                        placeholder="URL to shorten"
                        label="URL"
                        v-model="url"
                        clearable
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center mb-3 mt-n2">
                    <v-col class="d-flex justify-end">
                      <v-btn
                        color="secondary"
                        @click="shortenUrl"
                        :loading="loading"
                        >Shorten</v-btn
                      >
                    </v-col>
                    <v-col class="d-flex justify-start">
                      <v-btn color="secondary" :disabled="!url" @click="doCopy"
                        >Copy</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <notify />
    </v-main>
  </v-app>
</template>

<script>
import notify from "./components/notification.vue";
export default {
  name: "App",
  components: {
    notify,
  },
  data() {
    return {
      url: "",
      loading: false,
    };
  },
  methods: {
    shortenUrl: async function () {
      this.loading = true;

      let response = await fetch(this.$apiUrl + "/shorten", {
        method: "POST",
        body: JSON.stringify({ url: this.url }),
      });

      let responsePayload = await response.json();

      if (responsePayload.state) {
        this.url = responsePayload.shortUrl.replace("https://", "");
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Shortening URL",
          message: responsePayload.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {};
        if (responsePayload.message) {
          notify = {
            duration: 4000,
            type: "error",
            headline: "Shortening URL",
            message: responsePayload.message,
          };
        } else {
          notify = {
            duration: 4000,
            type: "error",
            headline: "Shortening URL",
            message: "Failed contacting Server!",
          };
        }
        this.$globalState.addNotification(notify);
      }

      this.loading = false;
    },
    onCopy: function () {
      let notify = {
        duration: 4000,
        type: "success",
        headline: "Successfully copied to clipboard!",
        message: "",
      };
      this.$globalState.addNotification(notify);
    },
    doCopy: function () {
      let copyText = this.url;
      if (
        copyText.includes("link.fantasyflip") ||
        copyText.includes("worker-url-shortener.fantasyflip")
      ) {
        copyText = "https://" + copyText;
      }
      this.$copyText(copyText);
    },
  },
};
</script>

<style scoped>
#app {
  background-color: var(--v-background-base);
}
</style>
