import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

Vue.config.productionTip = false;

Vue.prototype.$dev = Vue.config.devtools;

Vue.prototype.$apiUrl = "https://link.fantasyflip.de/api";

if (Vue.prototype.$dev) {
  Vue.prototype.$apiUrl = "http://127.0.0.1:8787/api";
}

const store = Vue.observable({
  notifications: [],
  addNotification(notification) {
    store.notifications.push(notification);
  },
  getAndRemoveNotification() {
    return store.notifications.shift();
  },
});

Vue.prototype.$globalState = store;

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
