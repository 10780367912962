<template>
  <div>
    <v-snackbar
      v-model="showNotification"
      :color="colorNotify"
      :bottom="bottom"
      :top="!bottom"
      :timeout="notification.duration"
      elevation="15"
      rounded
      transition="scroll-y-reverse-transition"
    >
      <v-row class="my-n4">
        <v-col align="center">
          <span class="font-weight-bold subtitle-1">{{
            notification.headline
          }}</span>
        </v-col>
      </v-row>
      <v-row v-if="notification.message" class="my-n4">
        <v-col align="center">
          {{ notification.message }}
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Notification",
  data() {
    return {
      colorNotify: "",
      showNotification: false,
    };
  },
  // poor computed :'(
  computed: {
    notification: function () {
      let notif = this.$globalState.getAndRemoveNotification();
      if (notif) {
        if (!notif.duration) {
          notif.duration = 2500;
        }
        this.changeNotificationState(true);
        this.setNotificationColor(notif.type);
        return notif;
      }
      this.changeNotificationState(false);
      return false;
    },
    bottom: function () {
      return !this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    changeNotificationState: function (input) {
      this.showNotification = input;
    },
    setNotificationColor: function (input) {
      switch (input) {
        case "info": {
          //blue
          this.colorNotify = "info";
          break;
        }
        case "success": {
          //green
          this.colorNotify = "secondary";
          break;
        }
        case "error": {
          //red
          this.colorNotify = "error";
          break;
        }
        default: {
          //grey
          this.colorNotify = "pending";
          break;
        }
      }
    },
  },
};

// let notify = {
//   duration: 4000,
//   type: "error",
//   headline: "Headline",
//   message: "Message",
// };
// this.$globalState.addNotification(notify);
</script>

<style scoped></style>
